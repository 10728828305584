import React from 'react'

const Instagram = ({className}) => {
    return (
        <svg className={className} viewBox="0 0 32 32" fill="#8C8C8C">
            <path d="M20.4501 13.32C20.327 13.3675 20.2145 13.4389 20.1191 13.5301C20.0238 13.6213 19.9474 13.7305 19.8944 13.8513C19.8414 13.9722 19.8129 14.1023 19.8104 14.2342C19.8079 14.3661 19.8316 14.4973 19.8801 14.62C20.2095 15.4749 20.2356 16.4169 19.9539 17.2887C19.6721 18.1604 19.0997 18.9091 18.3322 19.4094C17.5647 19.9097 16.6487 20.1314 15.7374 20.0375C14.8261 19.9435 13.9746 19.5395 13.3254 18.8931C12.6762 18.2467 12.2685 17.397 12.1706 16.4861C12.0727 15.5752 12.2904 14.6583 12.7874 13.8886C13.2844 13.119 14.0306 12.5433 14.9011 12.2578C15.7716 11.9723 16.7138 11.9942 17.5701 12.32C17.818 12.4141 18.0933 12.4059 18.3352 12.2972C18.5771 12.1884 18.7659 11.988 18.8601 11.74C18.9542 11.492 18.946 11.2168 18.8372 10.9749C18.7285 10.7329 18.528 10.5441 18.2801 10.45C16.9972 9.96473 15.5868 9.93364 14.2838 10.3619C12.9808 10.7902 11.8638 11.652 11.119 12.8037C10.3742 13.9554 10.0465 15.3276 10.1904 16.6916C10.3343 18.0556 10.9413 19.3291 11.9101 20.3C13.0351 21.4236 14.5601 22.0547 16.1501 22.0547C17.7401 22.0547 19.2651 21.4236 20.3901 20.3C21.2138 19.4784 21.7809 18.4351 22.0224 17.297C22.2638 16.159 22.1692 14.9753 21.7501 13.89C21.7025 13.7669 21.6311 13.6544 21.5399 13.5591C21.4487 13.4637 21.3396 13.3873 21.2187 13.3343C21.0979 13.2813 20.9678 13.2528 20.8358 13.2503C20.7039 13.2479 20.5728 13.2716 20.4501 13.32Z" />
            <path d="M23.0001 10C23.5523 10 24.0001 9.55228 24.0001 9C24.0001 8.44772 23.5523 8 23.0001 8C22.4478 8 22.0001 8.44772 22.0001 9C22.0001 9.55228 22.4478 10 23.0001 10Z" />
            <path d="M28.0001 8.99999C28.0003 7.69106 27.4873 6.43421 26.5711 5.49935C25.655 4.56449 24.4087 4.02617 23.1001 3.99999C18.4099 3.56938 13.6902 3.56938 9.00006 3.99999C7.68772 3.99982 6.42793 4.51561 5.49251 5.43606C4.5571 6.35651 4.02106 7.60782 4.00006 8.91999C3.64004 13.6064 3.64004 18.3136 4.00006 23C3.9998 24.3089 4.51283 25.5658 5.42899 26.5006C6.34516 27.4355 7.59138 27.9738 8.90006 28C11.2601 28.22 13.6301 28.34 16.0001 28.34C18.3701 28.34 20.7101 28.23 23.0501 28C24.3537 27.9871 25.6008 27.4656 26.5255 26.5465C27.4501 25.6274 27.9793 24.3836 28.0001 23.08C28.3801 18.3944 28.3801 13.6856 28.0001 8.99999ZM26.0001 23C26.0001 23.7956 25.684 24.5587 25.1214 25.1213C24.5588 25.6839 23.7957 26 23.0001 26H22.9001C18.2777 26.45 13.6225 26.45 9.00006 26C8.59927 26.0001 8.20252 25.92 7.83322 25.7642C7.46393 25.6085 7.12959 25.3803 6.84995 25.0932C6.5703 24.8061 6.35101 24.4659 6.20504 24.0926C6.05907 23.7194 5.98937 23.3206 6.00006 22.92C5.65005 18.2866 5.65005 13.6334 6.00006 8.99999C5.99988 8.59834 6.08035 8.20074 6.23671 7.83077C6.39308 7.4608 6.62214 7.12599 6.91032 6.84621C7.19849 6.56642 7.53992 6.34735 7.91435 6.20199C8.28878 6.05663 8.68858 5.98794 9.09006 5.99999C11.3834 5.79333 13.6834 5.68666 15.9901 5.67999C18.2967 5.67333 20.6334 5.77999 23.0001 5.99999C23.4008 5.99985 23.7976 6.08002 24.1669 6.23576C24.5362 6.3915 24.8705 6.61966 25.1502 6.90676C25.4298 7.19387 25.6491 7.5341 25.7951 7.90736C25.941 8.28062 26.0107 8.67935 26.0001 9.07999C26.38 13.7122 26.38 18.3678 26.0001 23Z" />
        </svg>
    )
}

export default Instagram;