
const PersonalLogo = ({className}) => {
    return (
        <svg className={className} viewBox="0 0 39 39" fill="white">
            <path className='logo-path' d="M34.9429 27.7822C34.4897 27.0339 34.0382 26.2872 33.5883 25.5422C30.6627 20.7074 27.7345 15.8735 24.8039 11.0404C24.4695 10.5154 24.3362 9.88448 24.429 9.26665C24.7307 6.76882 25.9049 4.46318 27.7387 2.76744C29.5725 1.07169 31.9447 0.0980187 34.4255 0.0228088C34.9678 0.00760508 35.2178 0.1267 35.2153 0.734848C35.2019 9.62394 35.2019 18.5113 35.2153 27.3971C35.208 27.5113 35.1922 27.6248 35.1678 27.7366L34.9429 27.7822ZM0.62229 0.16217C5.16536 0.698333 9.48678 2.44713 13.1454 5.23007C15.7464 7.24043 17.9637 9.71526 19.6882 12.5329C25.0047 21.0098 30.3062 29.4952 35.5926 37.9889C35.6343 38.0563 35.691 38.1247 35.749 38.1944C35.9242 38.4054 36.1103 38.6296 35.9225 38.8809C35.787 39.0691 35.5288 39.0015 35.281 38.9366C35.1952 38.9141 35.1106 38.892 35.0328 38.8809C27.0231 37.7077 20.8103 33.6964 16.4643 26.7661C11.0795 18.1777 5.70219 9.58423 0.332391 0.985703C0.297669 0.930381 0.253411 0.876306 0.208622 0.821584C0.0728793 0.655735 -0.0677371 0.483932 0.037493 0.253392C0.0999393 0.115586 0.251472 0.131063 0.42662 0.148951C0.489795 0.155404 0.556042 0.16217 0.62229 0.16217ZM1.16925 11.5462C1.0886 11.4267 0.99701 11.291 0.889682 11.1291C0.838674 11.3206 0.800269 11.5153 0.774722 11.7119C0.775477 13.1008 0.76665 14.4901 0.757822 15.8794C0.737433 19.0883 0.717043 22.2975 0.814708 25.5016C0.913449 28.2496 1.93587 30.8808 3.71234 32.9585C5.4888 35.0363 7.91251 36.4357 10.5813 36.9247C11.3685 37.059 11.6909 36.9754 11.6609 36.0454C11.5985 33.9676 11.626 31.8872 11.6609 29.8094C11.672 29.1527 11.4893 28.5076 11.1361 27.957C7.9339 22.6695 4.73168 17.3829 1.52946 12.0971C1.42628 11.9271 1.31583 11.7634 1.16925 11.5462Z" />
        </svg>
    )
};

export default PersonalLogo;

