import './NavBar.css'
import { useState } from 'react';
import PersonalLogo from '../react icons/PersonalLogo'



const NavBar = () => {
    const [isMobile, setIsMobile] = useState(false);
    const slideToHome = () => { document.getElementById('home')?.scrollIntoView({ behavior: 'smooth' }) }
    const slideToAbout = () => { document.getElementById('about')?.scrollIntoView({ behavior: 'smooth' }) }
    const slideToSkills = () => { document.getElementById('skills')?.scrollIntoView({ behavior: 'smooth' }) }
    const slideToProject = () => { document.getElementById('project')?.scrollIntoView({ behavior: 'smooth' }) }
    const slideToContact = () => { document.getElementById('contact')?.scrollIntoView({ behavior: 'smooth' }) }
    return (
        <header className="header-bar">
            <PersonalLogo className={"logo"} />
            <nav className={isMobile ? "nav-links-mobile" : "nav-links"}
                onClick={() => setIsMobile(false)}
            >
                <button className="link" onClick={slideToHome} >Home</button>
                <button className="link" onClick={slideToAbout} >About</button>
                <button className="link" onClick={slideToSkills} >Skills</button>
                <button className="link" onClick={slideToProject} >Project</button>
                <button className="link" onClick={slideToContact} >Contact</button>
            </nav>
            <button className="mobile-menu-icon" onClick={() => setIsMobile(!isMobile)}>
                <svg className='switch-button' viewBox="-20 -40 100 100" >
                    <path className={isMobile ? "active-path-one" : "path-one"} d="M5.41461 27.7304C6.48282 27.7226 7.51929 27.3663 8.36662 26.7157C9.21394 26.0652 9.8258 25.1558 10.1093 24.1258L18.9803 24.2001V21.4905H10.1093C9.86099 20.6106 9.3718 19.8176 8.69688 19.2008C8.02197 18.5841 7.18822 18.1682 6.28959 18C5.39095 17.8318 4.46319 17.9181 3.61095 18.249C2.75871 18.58 2.01588 19.1425 1.46621 19.873C0.916536 20.6036 0.581885 21.4733 0.5 22.3839C0.418115 23.2945 0.59223 24.2099 1.00275 25.0268C1.41326 25.8438 2.04384 26.5298 2.82337 27.0075C3.60289 27.4852 4.50039 27.7356 5.41461 27.7304Z" fill="#D9D9D9" />
                    <path className={isMobile ? "active-path-two" : "path-two"} d="M37.0001 23.0657C37.0079 21.9975 37.3642 20.961 38.0148 20.1137C38.6653 19.2663 39.5747 18.6545 40.6047 18.371L40.6047 0H43.3142L43.24 18.371C44.1199 18.6193 44.913 19.1085 45.5297 19.7834C46.1464 20.4583 46.5623 21.2921 46.7305 22.1907C46.8987 23.0893 46.8124 24.0171 46.4815 24.8693C46.1505 25.7216 45.5881 26.4644 44.8575 27.0141C44.1269 27.5637 43.2573 27.8984 42.3466 27.9803C41.436 28.0622 40.5206 27.888 39.7037 27.4775C38.8867 27.067 38.2007 26.4364 37.723 25.6569C37.2453 24.8774 36.9949 23.9799 37.0001 23.0657Z" fill="#D9D9D9" />
                </svg>
            </button>
        </header>
    )
};
export default NavBar;