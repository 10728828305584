import './Project.css'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ProjectCard from '../layout/Project-Card';
import { ProjectData } from '../../assets/cover projects/ProjectsData';

const Project = () => {
    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 4
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };
    return (
        <section id="project">
            <div className="project-contend">
                <h2 className='project-title'>Project</h2>
                <p className='project-description'>Personal projects developed while learning various technologies.</p>
                <div className="projects-container">
                    <Carousel responsive={responsive} className="projects-carousel">
                    {ProjectData.map((data, idx) => (
                        <ProjectCard cardData={data}/> 
                    ))}
                    </Carousel>
                </div>
            </div>
        </section>
    )
};

export default Project;