import './Circuit.css'

const Circuit = () => {

    

    return (
        <svg className='circuit' viewBox="0 0 1368 603" >
            <path className='circuit-path' d="M1353.72 378.848C1352.66 378.852 1351.62 379.208 1350.78 379.859C1349.94 380.511 1349.33 381.422 1349.06 382.452H1287.15L1261.89 357.19H1046.29L1005.07 398.294H932.376L882.521 348.436H797.864L723.899 422.436H533.353L479.712 476.172H458.086L421.043 513.218H353.863L273.204 593.882C272.173 593.318 270.974 593.141 269.825 593.386C268.676 593.63 267.652 594.279 266.941 595.215C266.229 596.15 265.877 597.309 265.948 598.482C266.019 599.655 266.509 600.764 267.328 601.607C268.17 602.426 269.279 602.915 270.452 602.987C271.625 603.058 272.784 602.705 273.719 601.994C274.655 601.282 275.303 600.259 275.548 599.109C275.792 597.96 275.616 596.761 275.052 595.73L354.953 515.823H422.012L459.055 478.777H480.651L534.323 425.102H724.838L799.106 351.041H881.522L931.347 400.93H1006.37L1047.47 359.795H1260.92L1286.18 385.057H1349.18C1349.45 386.087 1350.06 386.999 1350.9 387.65C1351.74 388.302 1352.78 388.657 1353.84 388.662C1355.14 388.662 1356.39 388.145 1357.31 387.225C1358.23 386.304 1358.75 385.056 1358.75 383.755C1358.75 382.453 1358.23 381.205 1357.31 380.285C1356.39 379.365 1355.14 378.848 1353.84 378.848H1353.72Z" fill="#8C8C8C" />
            <path className='circuit-path' d="M1362.48 140.125C1361.4 140.122 1360.36 140.473 1359.5 141.125C1358.65 141.777 1358.03 142.692 1357.75 143.73H1258.1L1237.14 164.691H1096.81L1069.55 137.43H1006.49L995.316 148.607H897.878L845.932 96.7489H745.192L700.214 141.731H608.651L549.952 83.0271H490.343L443.335 130.039H409.715L340.051 60.3696H300.131L247.034 7.3C247.572 6.3715 247.787 5.2908 247.644 4.22732C247.501 3.16385 247.01 2.17773 246.247 1.42358C245.602 0.774037 244.789 0.317717 243.899 0.10617C243.008 -0.105378 242.077 -0.0637214 241.209 0.226435C240.341 0.51659 239.571 1.04368 238.987 1.74816C238.403 2.45265 238.028 3.30644 237.903 4.21311C237.779 5.11978 237.91 6.04318 238.283 6.879C238.656 7.71482 239.255 8.42976 240.012 8.94304C240.77 9.45632 241.656 9.74749 242.57 9.78367C243.485 9.81984 244.391 9.59957 245.187 9.14775L299.04 63.0049H338.991L408.655 132.674H444.426L491.434 85.6927H548.982L607.682 144.366H701.274L746.283 99.3539H844.872L896.818 151.303H996.407L1007.58 140.156H1068.68L1095.94 167.417H1238.29L1259.25 146.456H1357.69C1357.91 147.353 1358.37 148.171 1359.03 148.816C1359.69 149.461 1360.52 149.906 1361.42 150.101C1362.33 150.296 1363.26 150.233 1364.13 149.918C1365 149.604 1365.76 149.051 1366.33 148.323C1366.9 147.595 1367.25 146.722 1367.34 145.804C1367.44 144.886 1367.27 143.959 1366.86 143.132C1366.45 142.304 1365.82 141.609 1365.03 141.125C1364.24 140.642 1363.34 140.39 1362.42 140.398L1362.48 140.125Z" fill="#8C8C8C" />
            <path className='blink-me' d="M1348.36 281.796C1347.29 281.804 1346.26 282.16 1345.41 282.81C1344.56 283.461 1343.95 284.37 1343.67 285.4H1151.94L1094.66 228.151H1017.79L973.145 272.769H861.319L811.858 223.304H730.078L711.269 242.115H639.818L610.529 212.823H552.708L521.147 244.387H460.236L417.984 202.131H340.899L285.865 147.123H94.2886L51.7331 104.564C52.3187 103.531 52.5101 102.321 52.2718 101.158C52.0334 99.995 51.3815 98.9578 50.4369 98.2386C49.4923 97.5195 48.319 97.1673 47.1345 97.2472C45.9501 97.3272 44.8348 97.8339 43.9953 98.6734C43.1558 99.5129 42.6492 100.628 42.5693 101.813C42.4894 102.997 42.8416 104.171 43.5606 105.115C44.2797 106.06 45.3168 106.712 46.4798 106.95C47.6428 107.189 48.8528 106.997 49.8855 106.412L93.1983 149.728H284.804L339.809 204.736H416.893L459.297 247.143H522.328L553.768 215.428H609.408L638.697 244.72H712.359L731.199 225.909H810.737L860.199 275.374H974.205L1018.88 230.816H1093.6L1150.85 288.096H1343.67C1343.89 288.984 1344.36 289.792 1345.01 290.427C1345.67 291.062 1346.5 291.499 1347.39 291.687C1348.29 291.876 1349.22 291.808 1350.08 291.492C1350.94 291.177 1351.69 290.626 1352.25 289.902C1352.81 289.179 1353.16 288.312 1353.25 287.401C1353.34 286.49 1353.17 285.572 1352.77 284.752C1352.36 283.932 1351.73 283.242 1350.95 282.762C1350.17 282.282 1349.28 282.031 1348.36 282.038V281.796Z" fill="#8C8C8C" />
            <path className='circuit-path' d="M1260.25 330.2L1289.3 359.249H1342.58C1342.89 360.394 1343.61 361.386 1344.6 362.041C1345.59 362.696 1346.79 362.969 1347.96 362.81C1349.14 362.651 1350.22 362.07 1351 361.176C1351.78 360.282 1352.21 359.135 1352.21 357.947C1352.21 356.759 1351.78 355.612 1351 354.718C1350.22 353.824 1349.14 353.243 1347.96 353.084C1346.79 352.925 1345.59 353.198 1344.6 353.853C1343.61 354.508 1342.89 355.499 1342.58 356.644H1290.36L1261.34 327.747H1141.97L1080.15 265.923H1031.21L992.257 304.908H847.356L803.922 261.501H739.801L705.211 296.063H596.808L564.732 264.136H432.28L355.316 341.105H202.177L172.433 370.851C171.401 370.265 170.191 370.074 169.028 370.312C167.865 370.55 166.828 371.202 166.109 372.147C165.389 373.092 165.037 374.265 165.117 375.45C165.197 376.634 165.704 377.75 166.543 378.589C167.383 379.429 168.498 379.935 169.682 380.015C170.867 380.095 172.04 379.743 172.985 379.024C173.929 378.305 174.581 377.267 174.82 376.104C175.058 374.941 174.867 373.731 174.281 372.699L203.267 343.74H356.377L433.37 266.741H563.612L595.687 298.849H706.12L740.861 264.106H802.832L846.235 307.513H993.287L1032.33 268.377H1079.09L1140.94 330.2H1260.25Z" fill="#8C8C8C" />
            <path className='circuit-path' d="M1091.21 246.598C1090.75 246.14 1090.21 245.777 1089.62 245.529C1089.02 245.28 1088.38 245.152 1087.74 245.15C1087.09 245.149 1086.45 245.275 1085.86 245.521C1085.26 245.766 1084.72 246.127 1084.26 246.583C1083.8 247.038 1083.44 247.579 1083.19 248.175C1082.94 248.771 1082.81 249.41 1082.81 250.055C1082.81 250.701 1082.94 251.34 1083.18 251.937C1083.43 252.534 1083.79 253.077 1084.24 253.534C1085.01 254.28 1085.99 254.759 1087.05 254.901C1088.11 255.043 1089.19 254.84 1090.12 254.322L1139.04 303.181H1264.07L1298.05 337.167H1339.58C1339.9 338.308 1340.62 339.294 1341.61 339.944C1342.6 340.595 1343.79 340.866 1344.96 340.707C1346.13 340.549 1347.21 339.971 1347.99 339.081C1348.77 338.191 1349.2 337.049 1349.21 335.865C1349.2 334.681 1348.77 333.539 1347.99 332.649C1347.21 331.759 1346.13 331.181 1344.96 331.023C1343.79 330.864 1342.6 331.135 1341.61 331.785C1340.62 332.436 1339.9 333.422 1339.58 334.562H1299.14L1265.16 300.485H1140.1L1091.97 252.383C1092.48 251.465 1092.68 250.407 1092.54 249.366C1092.41 248.324 1091.94 247.354 1091.21 246.598V246.598Z" fill="#8C8C8C" />
            <path className='circuit-path' d="M1099.39 332.957C1100.03 333.612 1100.84 334.073 1101.74 334.289C1102.63 334.505 1103.56 334.466 1104.43 334.178C1105.3 333.89 1106.07 333.364 1106.66 332.658C1107.25 331.953 1107.63 331.098 1107.75 330.189C1107.88 329.281 1107.74 328.355 1107.37 327.518C1107 326.68 1106.39 325.964 1105.63 325.451C1104.87 324.939 1103.98 324.649 1103.07 324.616C1102.15 324.582 1101.24 324.807 1100.45 325.263L1067.13 291.943H1034.93L996.255 330.625H852.414L808.193 286.309H750.129L707.21 329.231H591.114C590.799 328.086 590.079 327.094 589.088 326.44C588.097 325.785 586.903 325.512 585.726 325.671C584.549 325.83 583.47 326.41 582.688 327.305C581.907 328.199 581.476 329.346 581.476 330.534C581.476 331.721 581.907 332.869 582.688 333.763C583.47 334.657 584.549 335.238 585.726 335.397C586.903 335.556 588.097 335.283 589.088 334.628C590.079 333.973 590.799 332.981 591.114 331.836H708.24L751.159 288.914H807.012L851.294 333.199H997.315L1036.02 294.427H1066.01L1098.6 327.02C1098.04 327.953 1097.82 329.048 1097.96 330.126C1098.1 331.204 1098.61 332.202 1099.39 332.957V332.957Z" fill="#8C8C8C" />
            <path className='circuit-path' d="M1056.23 333.108H1026.27L1000.25 359.128H932.376C932.058 357.986 931.336 356.997 930.345 356.345C929.354 355.694 928.16 355.423 926.985 355.584C925.81 355.745 924.732 356.326 923.953 357.22C923.173 358.114 922.743 359.26 922.743 360.446C922.743 361.632 923.173 362.778 923.953 363.672C924.732 364.566 925.81 365.147 926.985 365.308C928.16 365.469 929.354 365.198 930.345 364.546C931.336 363.895 932.058 362.906 932.376 361.764H1001.37L1027.39 335.713H1056.29C1056.6 336.858 1057.32 337.85 1058.31 338.505C1059.3 339.16 1060.5 339.433 1061.68 339.274C1062.85 339.115 1063.93 338.534 1064.71 337.64C1065.49 336.746 1065.92 335.599 1065.92 334.411C1065.92 333.223 1065.49 332.076 1064.71 331.182C1063.93 330.288 1062.85 329.707 1061.68 329.548C1060.5 329.389 1059.3 329.662 1058.31 330.317C1057.32 330.972 1056.6 331.963 1056.29 333.108H1056.23Z" fill="#8C8C8C" />
            <path className='circuit-path' d="M187.275 503.434H252.335L302.947 452.848H428.706L490.889 390.661H711.996L779.297 323.355C780.231 323.873 781.307 324.076 782.366 323.934C783.424 323.792 784.409 323.313 785.173 322.567C785.823 321.923 786.279 321.109 786.491 320.219C786.702 319.328 786.661 318.396 786.371 317.529C786.08 316.661 785.553 315.891 784.849 315.307C784.144 314.723 783.291 314.347 782.384 314.223C781.478 314.098 780.554 314.23 779.718 314.603C778.883 314.975 778.168 315.574 777.654 316.332C777.141 317.09 776.85 317.976 776.814 318.89C776.778 319.805 776.998 320.711 777.45 321.507L710.815 388.147H489.707L427.555 450.304H301.948L251.335 500.92H186.275L138.571 548.628H56.2158C55.9006 547.483 55.1809 546.491 54.1901 545.836C53.1993 545.182 52.0047 544.908 50.8279 545.067C49.6511 545.227 48.5719 545.807 47.7904 546.701C47.0089 547.595 46.5783 548.743 46.5783 549.93C46.5783 551.118 47.0089 552.265 47.7904 553.159C48.5719 554.054 49.6511 554.634 50.8279 554.793C52.0047 554.952 53.1993 554.679 54.1901 554.024C55.1809 553.37 55.9006 552.378 56.2158 551.233H139.661L187.275 503.434Z" fill="#8C8C8C" />
            <path className='circuit-path' d="M569.397 374.031H691.218L750.584 314.661C751.614 315.226 752.813 315.402 753.963 315.158C755.112 314.913 756.135 314.264 756.847 313.329C757.558 312.394 757.911 311.234 757.84 310.061C757.769 308.888 757.279 307.78 756.46 306.937C755.617 306.118 754.509 305.628 753.336 305.557C752.163 305.486 751.003 305.838 750.068 306.55C749.133 307.262 748.484 308.285 748.24 309.435C747.995 310.584 748.171 311.783 748.736 312.814L690.067 371.426H570.427L534.08 335.077H460.963L406.928 389.116H271.629C271.314 387.971 270.594 386.979 269.603 386.325C268.612 385.67 267.418 385.397 266.241 385.556C265.064 385.715 263.985 386.295 263.203 387.19C262.422 388.084 261.991 389.231 261.991 390.419C261.991 391.606 262.422 392.754 263.203 393.648C263.985 394.542 265.064 395.123 266.241 395.282C267.418 395.441 268.612 395.168 269.603 394.513C270.594 393.858 271.314 392.866 271.629 391.721H407.928L461.963 337.682H532.808L569.397 374.031Z" fill="#8C8C8C" />
            <path className='circuit-path' d="M217.018 459.33L258.211 418.135H424.466L480.863 361.764H516.967C517.286 362.906 518.007 363.895 518.998 364.546C519.99 365.198 521.183 365.468 522.359 365.308C523.534 365.147 524.611 364.566 525.391 363.672C526.171 362.778 526.601 361.632 526.601 360.446C526.601 359.26 526.171 358.114 525.391 357.22C524.611 356.326 523.534 355.745 522.359 355.584C521.183 355.423 519.99 355.694 518.998 356.345C518.007 356.997 517.286 357.986 516.967 359.128H479.773L423.527 415.59H257.151L215.928 456.786H157.38L127.273 486.895C126.24 486.309 125.03 486.118 123.867 486.356C122.704 486.595 121.667 487.247 120.948 488.191C120.229 489.136 119.877 490.309 119.957 491.494C120.037 492.678 120.543 493.794 121.383 494.633C122.222 495.473 123.338 495.98 124.522 496.06C125.707 496.139 126.88 495.787 127.824 495.068C128.769 494.349 129.421 493.312 129.659 492.149C129.898 490.986 129.706 489.776 129.121 488.743L158.44 459.391L217.018 459.33Z" fill="#8C8C8C" />
            <path className='circuit-path' d="M252.093 358.038H370.218L439.64 288.641H544.076L572.971 317.569H650.692C651.007 318.714 651.727 319.706 652.717 320.361C653.708 321.016 654.903 321.289 656.08 321.13C657.256 320.971 658.336 320.39 659.117 319.496C659.899 318.602 660.329 317.454 660.329 316.267C660.329 315.079 659.899 313.932 659.117 313.038C658.336 312.143 657.256 311.563 656.08 311.404C654.903 311.245 653.708 311.518 652.717 312.173C651.727 312.827 651.007 313.819 650.692 314.964H574.092L545.196 286.036H438.671L369.28 355.433H252.093C251.777 354.288 251.058 353.296 250.067 352.641C249.076 351.987 247.882 351.713 246.705 351.872C245.528 352.031 244.449 352.612 243.667 353.506C242.886 354.4 242.455 355.548 242.455 356.735C242.455 357.923 242.886 359.07 243.667 359.964C244.449 360.859 245.528 361.439 246.705 361.598C247.882 361.757 249.076 361.484 250.067 360.829C251.058 360.175 251.777 359.183 252.093 358.038V358.038Z" fill="#8C8C8C" />
            <path className='blink-me' d="M122.336 438.006C123.408 437.999 124.449 437.644 125.302 436.994C126.154 436.344 126.772 435.434 127.061 434.401H174.705L235.07 374.031H389.058L451.937 311.148H536.17L573.728 348.739H673.105C673.421 349.884 674.14 350.875 675.131 351.53C676.122 352.185 677.316 352.458 678.493 352.299C679.67 352.14 680.749 351.559 681.531 350.665C682.312 349.771 682.743 348.624 682.743 347.436C682.743 346.248 682.312 345.101 681.531 344.207C680.749 343.313 679.67 342.732 678.493 342.573C677.316 342.414 676.122 342.687 675.131 343.342C674.14 343.997 673.421 344.989 673.105 346.134H574.97L537.382 308.543H450.786L387.877 371.426H234.01L173.645 431.796H127.061C126.818 430.914 126.334 430.118 125.662 429.496C124.991 428.875 124.159 428.454 123.261 428.281C122.363 428.107 121.435 428.188 120.58 428.514C119.726 428.84 118.979 429.399 118.425 430.127C117.872 430.855 117.532 431.723 117.446 432.634C117.359 433.544 117.528 434.461 117.935 435.28C118.342 436.1 118.969 436.789 119.747 437.271C120.525 437.752 121.421 438.007 122.336 438.006V438.006Z" fill="#8C8C8C" />
            <path className='circuit-path' d="M291.104 539.238L343.504 486.804H425.283L504.034 408.048H702.031C702.346 409.193 703.066 410.185 704.057 410.84C705.047 411.494 706.242 411.767 707.419 411.608C708.596 411.449 709.675 410.869 710.456 409.975C711.238 409.08 711.669 407.933 711.669 406.746C711.669 405.558 711.238 404.411 710.456 403.516C709.675 402.622 708.596 402.042 707.419 401.883C706.242 401.724 705.047 401.997 704.057 402.651C703.066 403.306 702.346 404.298 702.031 405.443H502.913L424.163 484.199H342.383L290.014 536.754H206.538L154.2 589.096C153.276 588.567 152.205 588.355 151.149 588.492C150.094 588.629 149.112 589.107 148.354 589.854C147.44 590.783 146.928 592.034 146.928 593.337C146.928 594.64 147.44 595.891 148.354 596.821C149.186 597.672 150.301 598.189 151.489 598.275C152.677 598.361 153.854 598.01 154.801 597.287C155.747 596.564 156.396 595.52 156.626 594.352C156.855 593.183 156.65 591.971 156.047 590.944L207.538 539.45L291.104 539.238Z" fill="#8C8C8C" />
            <path className='circuit-path' d="M1354.39 174.506C1355.31 174.52 1356.22 174.273 1357.01 173.793C1357.8 173.313 1358.44 172.619 1358.85 171.792C1359.27 170.966 1359.44 170.039 1359.35 169.119C1359.26 168.199 1358.91 167.324 1358.34 166.593C1357.78 165.862 1357.01 165.307 1356.15 164.99C1355.28 164.673 1354.34 164.607 1353.43 164.801C1352.53 164.995 1351.7 165.44 1351.04 166.085C1350.38 166.73 1349.91 167.549 1349.69 168.447H1270.58L1240.29 198.738H1110.05C1109.77 197.711 1109.17 196.802 1108.32 196.151C1107.48 195.5 1106.45 195.143 1105.39 195.134C1104.08 195.134 1102.84 195.651 1101.92 196.571C1101 197.491 1100.48 198.739 1100.48 200.041C1100.48 201.342 1101 202.59 1101.92 203.511C1102.84 204.431 1104.08 204.948 1105.39 204.948C1106.45 204.945 1107.49 204.591 1108.34 203.939C1109.19 203.288 1109.8 202.376 1110.08 201.343H1241.32L1271.61 171.052H1349.63C1349.95 172.064 1350.58 172.947 1351.44 173.57C1352.29 174.192 1353.33 174.52 1354.39 174.506V174.506Z" fill="#8C8C8C" />
            <path className='circuit-path' d="M1193.92 179.14C1192.85 179.148 1191.81 179.504 1190.97 180.155C1190.12 180.805 1189.51 181.715 1189.22 182.745H1079.4L1056.98 160.329H885.641L841.934 116.589H758.792L704.03 171.355H583.511L534.262 122.133H481.226L445.122 158.118H392.662L346.381 111.925H104.072C103.757 110.78 103.037 109.788 102.046 109.133C101.055 108.478 99.8609 108.205 98.684 108.364C97.5072 108.523 96.4279 109.104 95.6465 109.998C94.865 110.892 94.4344 112.04 94.4344 113.227C94.4344 114.415 94.865 115.562 95.6465 116.456C96.4279 117.351 97.5072 117.931 98.684 118.09C99.8609 118.249 101.055 117.976 102.046 117.321C103.037 116.667 103.757 115.675 104.072 114.53H345.442L391.754 160.814H446.273L482.287 124.798H533.172L582.421 174.051H705.211L759.973 119.285H840.935L884.672 162.995H1055.98L1078.4 185.41H1189.22C1189.45 186.298 1189.91 187.106 1190.57 187.741C1191.23 188.376 1192.06 188.813 1192.95 189.001C1193.85 189.19 1194.78 189.122 1195.64 188.806C1196.5 188.491 1197.25 187.94 1197.81 187.216C1198.37 186.493 1198.72 185.626 1198.81 184.715C1198.9 183.805 1198.73 182.886 1198.33 182.066C1197.92 181.246 1197.29 180.556 1196.51 180.076C1195.73 179.596 1194.83 179.345 1193.92 179.352V179.14Z" fill="#8C8C8C" />
            <path className='circuit-path' d="M668.077 214.914H645.664L621.645 190.893H530.385L508.335 212.854H459.176L433.28 186.955H353.62L293.043 126.373H111.311C110.992 125.222 110.267 124.226 109.27 123.569C108.273 122.911 107.071 122.638 105.888 122.799C104.705 122.959 103.62 123.544 102.834 124.443C102.049 125.343 101.616 126.497 101.616 127.691C101.616 128.885 102.049 130.039 102.834 130.939C103.62 131.838 104.705 132.423 105.888 132.584C107.071 132.744 108.273 132.471 109.27 131.813C110.267 131.156 110.992 130.16 111.311 129.009H292.074L352.651 189.59H432.31L458.177 215.489H509.668L531.597 193.528H620.706L644.725 217.549H669.319L689.189 197.678H811.706L861.592 247.567H950.852L986.896 211.4H1066.37C1066.69 212.543 1067.41 213.531 1068.4 214.183C1069.4 214.834 1070.59 215.105 1071.76 214.944C1072.94 214.783 1074.02 214.202 1074.8 213.308C1075.58 212.415 1076.01 211.268 1076.01 210.082C1076.01 208.896 1075.58 207.75 1074.8 206.856C1074.02 205.962 1072.94 205.381 1071.76 205.22C1070.59 205.06 1069.4 205.33 1068.4 205.982C1067.41 206.633 1066.69 207.622 1066.37 208.765H985.806L949.641 244.932H862.531L812.645 195.043H687.947L668.077 214.914Z" fill="#8C8C8C" />
            <path className='circuit-path' d="M1244.56 217.155L1270.58 243.205H1339.58C1339.9 244.356 1340.62 245.353 1341.62 246.01C1342.62 246.667 1343.82 246.941 1345 246.78C1346.18 246.619 1347.27 246.035 1348.05 245.135C1348.84 244.236 1349.27 243.082 1349.27 241.888C1349.27 240.693 1348.84 239.539 1348.05 238.64C1347.27 237.74 1346.18 237.156 1345 236.995C1343.82 236.834 1342.62 237.108 1341.62 237.765C1340.62 238.422 1339.9 239.419 1339.58 240.57H1271.61L1245.59 214.55H1126.04C1125.73 213.405 1125.01 212.413 1124.02 211.758C1123.03 211.104 1121.83 210.831 1120.65 210.99C1119.48 211.149 1118.4 211.729 1117.62 212.623C1116.84 213.518 1116.4 214.665 1116.4 215.853C1116.4 217.04 1116.84 218.188 1117.62 219.082C1118.4 219.976 1119.48 220.556 1120.65 220.715C1121.83 220.875 1123.03 220.601 1124.02 219.947C1125.01 219.292 1125.73 218.3 1126.04 217.155H1244.56Z" fill="#8C8C8C" />
            <path className='circuit-path' d="M1355.84 265.893C1354.77 265.901 1353.74 266.257 1352.89 266.908C1352.04 267.558 1351.43 268.468 1351.15 269.498H1255.77L1229.02 242.751H1143.4C1143.08 241.606 1142.36 240.614 1141.37 239.959C1140.38 239.305 1139.19 239.031 1138.01 239.19C1136.83 239.35 1135.75 239.93 1134.97 240.824C1134.19 241.718 1133.76 242.866 1133.76 244.053C1133.76 245.241 1134.19 246.388 1134.97 247.283C1135.75 248.177 1136.83 248.757 1138.01 248.916C1139.19 249.075 1140.38 248.802 1141.37 248.147C1142.36 247.493 1143.08 246.501 1143.4 245.356H1227.93L1254.68 272.103H1351.15C1351.37 272.991 1351.84 273.798 1352.5 274.434C1353.16 275.069 1353.98 275.505 1354.88 275.694C1355.77 275.882 1356.7 275.815 1357.56 275.499C1358.42 275.183 1359.17 274.632 1359.73 273.909C1360.29 273.185 1360.64 272.318 1360.73 271.408C1360.82 270.497 1360.66 269.579 1360.25 268.758C1359.84 267.938 1359.22 267.248 1358.44 266.768C1357.66 266.289 1356.76 266.038 1355.84 266.045V265.893Z" fill="#8C8C8C" />
            <path className='circuit-path' d="M758.822 157.088C758.507 155.943 757.787 154.951 756.797 154.297C755.806 153.642 754.611 153.369 753.434 153.528C752.258 153.687 751.178 154.267 750.397 155.162C749.615 156.056 749.185 157.203 749.185 158.391C749.185 159.578 749.615 160.726 750.397 161.62C751.178 162.514 752.258 163.095 753.434 163.254C754.611 163.413 755.806 163.14 756.797 162.485C757.787 161.83 758.507 160.838 758.822 159.693H798.924L859.986 220.79H936.98L962.271 195.497H1050.5C1050.82 196.64 1051.54 197.628 1052.53 198.28C1053.52 198.932 1054.72 199.202 1055.89 199.041C1057.07 198.881 1058.15 198.3 1058.93 197.406C1059.71 196.512 1060.14 195.366 1060.14 194.179C1060.14 192.993 1059.71 191.847 1058.93 190.953C1058.15 190.059 1057.07 189.478 1055.89 189.318C1054.72 189.157 1053.52 189.427 1052.53 190.079C1051.54 190.73 1050.82 191.719 1050.5 192.862H961.181L935.89 218.185H861.077L799.985 157.088H758.822Z" fill="#8C8C8C" />
            <path className='circuit-path' d="M827.729 147.183C827.274 146.726 826.732 146.362 826.137 146.114C825.541 145.866 824.902 145.737 824.256 145.736C823.611 145.734 822.972 145.86 822.375 146.106C821.778 146.352 821.235 146.713 820.778 147.168C820.32 147.623 819.957 148.165 819.709 148.76C819.46 149.356 819.332 149.995 819.331 150.641C819.329 151.286 819.455 151.926 819.701 152.523C819.946 153.12 820.307 153.662 820.763 154.12C821.519 154.878 822.505 155.367 823.567 155.509C824.629 155.651 825.709 155.44 826.639 154.907L874.464 202.706H924.713L949.278 178.14H1034.54C1034.86 179.285 1035.58 180.277 1036.57 180.932C1037.56 181.587 1038.75 181.86 1039.93 181.701C1041.1 181.542 1042.18 180.961 1042.97 180.067C1043.75 179.173 1044.18 178.026 1044.18 176.838C1044.18 175.65 1043.75 174.503 1042.97 173.609C1042.18 172.715 1041.1 172.134 1039.93 171.975C1038.75 171.816 1037.56 172.089 1036.57 172.744C1035.58 173.399 1034.86 174.39 1034.54 175.535H948.187L923.623 200.101H875.555L828.486 153.06C829.018 152.131 829.231 151.055 829.095 149.994C828.958 148.933 828.478 147.946 827.729 147.183V147.183Z" fill="#8C8C8C" />
            <path className='circuit-path' d="M189.183 303.514L140.297 352.404C139.264 351.818 138.054 351.627 136.891 351.865C135.728 352.103 134.691 352.755 133.972 353.7C133.253 354.645 132.901 355.818 132.981 357.003C133.061 358.187 133.567 359.303 134.407 360.142C135.246 360.982 136.362 361.488 137.546 361.568C138.731 361.648 139.904 361.296 140.849 360.577C141.793 359.858 142.445 358.82 142.683 357.657C142.922 356.494 142.73 355.284 142.145 354.251L190.304 306.089H360.163L419.528 246.719C420.561 247.305 421.771 247.496 422.934 247.258C424.097 247.019 425.134 246.367 425.853 245.423C426.572 244.478 426.925 243.305 426.845 242.12C426.765 240.936 426.258 239.82 425.419 238.981C424.579 238.141 423.464 237.634 422.279 237.554C421.095 237.475 419.922 237.827 418.977 238.546C418.032 239.265 417.38 240.302 417.142 241.465C416.904 242.628 417.095 243.838 417.681 244.871L359.042 303.514H189.183Z" fill="#8C8C8C" />
            <path className='circuit-path' d="M53.2778 183.199H104.617L128.454 207.038H182.974L210.446 179.564H278.747L326.603 227.302H399.932C400.25 228.445 400.972 229.434 401.963 230.085C402.954 230.737 404.148 231.007 405.323 230.847C406.498 230.686 407.576 230.105 408.356 229.211C409.135 228.317 409.565 227.171 409.565 225.985C409.565 224.799 409.135 223.652 408.356 222.759C407.576 221.865 406.498 221.284 405.323 221.123C404.148 220.962 402.954 221.233 401.963 221.884C400.972 222.536 400.25 223.524 399.932 224.667H327.602L279.898 176.959H209.416L181.974 204.433H129.545L105.677 180.564H54.3682L9.14719 135.339C9.73283 134.307 9.92422 133.097 9.68589 131.934C9.44756 130.771 8.79566 129.733 7.85104 129.014C6.90642 128.295 5.73313 127.943 4.54866 128.023C3.36419 128.103 2.24888 128.609 1.40942 129.449C0.569971 130.288 0.0633296 131.404 -0.0165972 132.588C-0.0965241 133.773 0.255688 134.946 0.974754 135.891C1.69382 136.836 2.73095 137.488 3.89395 137.726C5.05695 137.964 6.26692 137.773 7.29958 137.187L53.2778 183.199Z" fill="#8C8C8C" />
            <path className='circuit-path' d="M285.683 274.556L250.608 239.51H207.992L174.917 272.557H127.031L102.467 297.153C101.434 296.567 100.224 296.376 99.0609 296.614C97.8979 296.853 96.8608 297.505 96.1417 298.449C95.4226 299.394 95.0704 300.567 95.1503 301.752C95.2303 302.937 95.737 304.052 96.5764 304.892C97.4159 305.731 98.5312 306.238 99.7156 306.318C100.9 306.398 102.073 306.045 103.018 305.326C103.963 304.607 104.614 303.57 104.853 302.407C105.091 301.244 104.9 300.034 104.314 299.001L128.121 275.192H176.007L209.083 242.115H249.579L284.623 277.161H350.046L376.155 251.05C377.188 251.636 378.398 251.828 379.561 251.589C380.724 251.351 381.761 250.699 382.48 249.754C383.199 248.81 383.551 247.636 383.471 246.452C383.391 245.267 382.885 244.152 382.045 243.312C381.206 242.473 380.09 241.966 378.906 241.886C377.721 241.806 376.548 242.158 375.604 242.877C374.659 243.597 374.007 244.634 373.769 245.797C373.53 246.96 373.722 248.17 374.307 249.203L348.956 274.556H285.683Z" fill="#8C8C8C" />
            <path className='circuit-path' d="M219.169 290.913C219.487 292.056 220.209 293.045 221.2 293.696C222.191 294.348 223.385 294.618 224.56 294.457C225.736 294.297 226.813 293.716 227.593 292.822C228.372 291.928 228.802 290.782 228.802 289.596C228.802 288.409 228.372 287.263 227.593 286.369C226.813 285.476 225.736 284.895 224.56 284.734C223.385 284.573 222.191 284.843 221.2 285.495C220.209 286.147 219.487 287.135 219.169 288.278H147.264L92.9559 342.62C92.1645 342.174 91.2645 341.958 90.3571 341.997C89.4498 342.035 88.5711 342.326 87.8203 342.837C87.0694 343.348 86.4762 344.059 86.1074 344.889C85.7386 345.719 85.6088 346.635 85.7328 347.535C85.8568 348.435 86.2295 349.282 86.8091 349.981C87.3886 350.681 88.1519 351.204 89.013 351.493C89.874 351.782 90.7986 351.825 91.6825 351.616C92.5665 351.408 93.3746 350.957 94.016 350.314C94.7722 349.562 95.2592 348.581 95.4016 347.524C95.544 346.467 95.3338 345.393 94.8036 344.467L148.354 290.913H219.169Z" fill="#8C8C8C" />
            <path className='circuit-path' d="M107.858 251.505H159.561L184.458 226.575H285.319L306.279 247.537H346.351C346.67 248.677 347.391 249.663 348.38 250.314C349.369 250.964 350.56 251.235 351.734 251.077C352.907 250.918 353.983 250.34 354.764 249.451C355.545 248.561 355.978 247.418 355.983 246.234C355.978 245.05 355.545 243.908 354.764 243.018C353.983 242.128 352.907 241.55 351.734 241.392C350.56 241.233 349.369 241.504 348.38 242.155C347.391 242.805 346.67 243.792 346.351 244.932H307.37L286.41 223.97H183.428L158.44 248.991H106.768L34.0748 321.537C33.1435 321.01 32.0652 320.801 31.0044 320.943C29.9435 321.085 28.9582 321.571 28.1987 322.325C27.7399 322.778 27.3757 323.319 27.1271 323.914C26.8785 324.509 26.7505 325.148 26.7505 325.793C26.7505 326.438 26.8785 327.077 27.1271 327.672C27.3757 328.268 27.7399 328.808 28.1987 329.261C29.0412 330.081 30.1497 330.57 31.3227 330.641C32.4956 330.712 33.6551 330.36 34.5903 329.649C35.5255 328.937 36.1743 327.914 36.4188 326.764C36.6632 325.615 36.4871 324.416 35.9224 323.385L107.858 251.505Z" fill="#8C8C8C" />
            <path className='circuit-path' d="M301.342 225.97C301.796 226.429 302.336 226.793 302.931 227.041C303.526 227.29 304.165 227.418 304.81 227.418C305.455 227.418 306.094 227.29 306.689 227.041C307.285 226.793 307.825 226.429 308.278 225.97C309.13 225.137 309.647 224.022 309.733 222.834C309.819 221.647 309.467 220.469 308.745 219.522C308.022 218.576 306.978 217.927 305.81 217.697C304.641 217.468 303.429 217.673 302.402 218.276L282.078 197.92H226.802C226.486 196.775 225.767 195.784 224.776 195.129C223.785 194.474 222.591 194.201 221.414 194.36C220.237 194.519 219.158 195.099 218.376 195.994C217.595 196.888 217.164 198.035 217.164 199.223C217.164 200.41 217.595 201.558 218.376 202.452C219.158 203.346 220.237 203.927 221.414 204.086C222.591 204.245 223.785 203.972 224.776 203.317C225.767 202.662 226.486 201.67 226.802 200.525H280.988L300.555 220.124C300.029 221.05 299.822 222.124 299.964 223.179C300.106 224.235 300.59 225.215 301.342 225.97V225.97Z" fill="#8C8C8C" />
            <path className='circuit-path' d="M51.4908 14.7516C52.5642 14.7504 53.6076 14.3971 54.461 13.7461C55.3145 13.095 55.9309 12.182 56.2158 11.147H123.396L137.117 24.8385H216.655L275.93 84.1177H334.811C335.126 85.2627 335.846 86.2545 336.837 86.9092C337.828 87.564 339.022 87.8371 340.199 87.6781C341.376 87.519 342.455 86.9386 343.236 86.0443C344.018 85.1501 344.449 84.0028 344.449 82.8152C344.449 81.6275 344.018 80.4802 343.236 79.586C342.455 78.6918 341.376 78.1113 340.199 77.9522C339.022 77.7932 337.828 78.0664 336.837 78.7211C335.846 79.3758 335.126 80.3676 334.811 81.5126H277.02L217.745 22.2638H138.207L124.486 8.54199H56.2158C55.973 7.66009 55.4886 6.8636 54.8172 6.24243C54.1458 5.62125 53.3142 5.20011 52.4162 5.02654C51.5181 4.85296 50.5895 4.93387 49.735 5.26011C48.8805 5.58636 48.1342 6.14496 47.5803 6.87287C47.0264 7.60079 46.687 8.46904 46.6003 9.37963C46.5137 10.2902 46.6833 11.2069 47.09 12.0262C47.4966 12.8456 48.1242 13.5349 48.9018 14.0165C49.6794 14.4981 50.5761 14.7527 51.4908 14.7516V14.7516Z" fill="#8C8C8C" />
            <path className='circuit-path' d="M6.6938 41.8619C7.76202 41.854 8.79849 41.4978 9.64581 40.8472C10.4931 40.1966 11.105 39.2873 11.3885 38.2573H120.882L132.755 50.1616H198.512L249.851 101.656H318.849C319.164 102.801 319.884 103.793 320.875 104.448C321.865 105.102 323.06 105.375 324.237 105.216C325.414 105.057 326.493 104.477 327.274 103.583C328.056 102.688 328.486 101.541 328.486 100.354C328.486 99.1659 328.056 98.0186 327.274 97.1244C326.493 96.2301 325.414 95.6497 324.237 95.4906C323.06 95.3316 321.865 95.6047 320.875 96.2595C319.884 96.9142 319.164 97.906 318.849 99.051H250.881L199.572 47.5566H133.876L121.942 35.622H11.3885C11.1402 34.7421 10.651 33.949 9.97608 33.3323C9.30117 32.7155 8.46742 32.2996 7.56878 32.1315C6.67015 31.9633 5.74238 32.0495 4.89015 32.3805C4.03791 32.7114 3.29508 33.2739 2.74541 34.0045C2.19573 34.7351 1.86108 35.6047 1.7792 36.5153C1.69731 37.426 1.87143 38.3414 2.28194 39.1583C2.69246 39.9752 3.32304 40.6612 4.10256 41.1389C4.88209 41.6166 5.77959 41.867 6.6938 41.8619V41.8619Z" fill="#8C8C8C" />
            <path className='circuit-path' d="M83.8996 51.04C83.255 50.3905 82.4416 49.9342 81.5513 49.7226C80.6609 49.5111 79.7292 49.5527 78.8614 49.8429C77.9935 50.133 77.224 50.6601 76.6399 51.3646C76.0558 52.0691 75.6803 52.9229 75.5558 53.8296C75.4314 54.7362 75.563 55.6596 75.9357 56.4955C76.3084 57.3313 76.9074 58.0462 77.665 58.5595C78.4227 59.0728 79.3087 59.3639 80.2231 59.4001C81.1375 59.4363 82.0438 59.216 82.8396 58.7642L114.007 89.9335H191.667C191.982 91.0785 192.702 92.0703 193.693 92.7251C194.683 93.3798 195.878 93.6529 197.055 93.4939C198.232 93.3348 199.311 92.7544 200.092 91.8601C200.874 90.9659 201.304 89.8186 201.304 88.631C201.304 87.4434 200.874 86.2961 200.092 85.4018C199.311 84.5076 198.232 83.9271 197.055 83.7681C195.878 83.609 194.683 83.8822 193.693 84.5369C192.702 85.1916 191.982 86.1835 191.667 87.3285H115.067L84.778 57.0376C85.314 56.0811 85.5184 54.9742 85.3595 53.8893C85.2007 52.8045 84.6873 51.8027 83.8996 51.04V51.04Z" fill="#8C8C8C" />
        </svg>


    )
};
export default Circuit;