import './SocialMediaLinks.css'
import { useState } from 'react';
import LinkedIn from '../react icons/LinkedIn';
import Instagram from '../react icons/Instagram'
import YouTube from '../react icons/YouTube'
import GitHub from '../react icons/GitHub';

const SocialMediaLinks = () => {
    const [isOpen, setIsOpen] = useState(true);
    return (
        <div className={isOpen ? "social-media-stack" : "social-media-stack-close"}
            onClick={() => setIsOpen(!isOpen)}>
                
            <svg className={isOpen ? "arrow" : "arrow-close"} viewBox="0 0 492.004 492.004" fill="#000000" onClick={() => setIsOpen(!isOpen)}>
                <path className='arrow-line' d="M382.678,226.804L163.73,7.86C158.666,2.792,151.906,0,144.698,0s-13.968,2.792-19.032,7.86l-16.124,16.12
			c-10.492,10.504-10.492,27.576,0,38.064L293.398,245.9l-184.06,184.06c-5.064,5.068-7.86,11.824-7.86,19.028
			c0,7.212,2.796,13.968,7.86,19.04l16.124,16.116c5.068,5.068,11.824,7.86,19.032,7.86s13.968-2.792,19.032-7.86L382.678,265
			c5.076-5.084,7.864-11.872,7.848-19.088C390.542,238.668,387.754,231.884,382.678,226.804z"></path>
            </svg>

            <a href='https://github.com/NunoLima10'><GitHub className={"social-link"}/></a>
            <a href='https://www.youtube.com/@NunoLima10'><YouTube className={"social-link"}/></a>
            <a href='https://www.instagram.com/nunolima10/'><Instagram className={"social-link"}/></a>
            <a href='https://www.linkedin.com/in/nuno-lima-5899b6251/'><LinkedIn className={"social-link"} /></a>
            <svg className="line" viewBox="0 0 10 93" fill="#8C8C8C">
                <path className='link-path' d="M7 9.58392C8.76593 8.81238 10 7.05029 10 5C10 2.23859 7.76141 0 5 0C2.23859 0 0 2.23859 0 5C0 7.05029 1.23407 8.81238 3 9.58392V107H7V9.58392Z" />
            </svg>
        </div >
    );
}
export default SocialMediaLinks;