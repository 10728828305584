import Circuit from "../layout/Circuit";
import './Home.css'

const Home = () => {
    return (
        <section id="home">
            <section className="front-page-container" id="home">
                <div className="left-container">
                    <div className="text-container">
                        <p className="text">
                            <span className="title">Hey my name is</span><br />
                            <span className="name">Nuno Lima</span><br /> 
                            <span className="description">I'm a computer engineering student,
                            I  <span className="highlight">learn</span> and <span className="highlight">teach</span> something new every day<span className="highlight">!</span>
                            </span>
                        </p>
                    </div>
                </div>
                <div className="right-container">
                    <Circuit />
                </div>
            </section>

        </section>
    )

};

export default Home;