
import SkillsItem from '../layout/SkillsItem';
import { SoftWaresAndFrameWorksData, LanguagesData } from '../../assets/skills icons/SkllisData';
import './Skills.css'




const Skills = () =>{
    return (
    <section id="skills">
        <div className="skills-contend">
            <h2 className='skills-title'>Skills</h2>
        <p className='skills-description'>As a junior developer I try to test various technologies thus creating an ease in learning new concepts.</p>
        
        <div className="skills-item-container">
            <div className="languages-skills">
                <h3 className="skills-container-title"> Languages</h3>
                <div className="items-container"> 
                    {LanguagesData.map((icon, idx) => (
                        <SkillsItem key={idx} icon={icon}/>
                    ))}    
                </div>
            </div>
            <div className="softwares-skills">
                <h3 className="skills-container-title">SoftWares and FrameWorks</h3>
                <div className="items-container"> 
                    {SoftWaresAndFrameWorksData.map((icon, idx) => (
                        <SkillsItem key={idx} icon={icon}/>
                    ))}    
                </div>
            </div>
        </div>
        </div>
    </section>
    )
};

export default Skills;