import './About.css'

import imagePath from '../../assets/about-image.jpg'
const About = () => {
    return (
        <section id="about">
            <div className="about-contend">
                <div className="about-image-box">
                    <img className='about-image' src={imagePath} alt='about' />
                </div>
                <div className="about-text-container">
                    <h2 className='about-title'>About</h2>
                    <p className='about-description'>My name is Nuno Lima, I'm 21 years old, currently studying computer engineering and telecommunications at the Atlantic Technical University in Cape Verde.</p>
                    <p className='about-description'>Technology enthusiasts and nature love, trying to understand the world and solidify a strong foundation, I believe that the best way is to seek knowledge in the most diverse areas related or not to technology.</p>
                </div>
            </div>
        </section>
    )                       
};

export default About;