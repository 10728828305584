
import NavBar from './components/layout/NavBar';
import Home from './components/pages/Home';
import About from './components/pages/About';
import SocialMediaLinks from './components/layout/SocialMediaLinks';
import Skills from './components/pages/Skills';
import Project from './components/pages/Project';
import Contact from './components/pages/Contact'




const App = () => {
  return (
    <>
    <NavBar/>
    <SocialMediaLinks/>
    <Home />
    <About />
    <Skills />
    <Project />
    <Contact />
    </>

  )
}

export default App;
