 import cover1  from "./cover1.jpg"
 import cover2  from "./cover2.jpg"
 import cover3  from "./cover3.jpg"
 import cover4  from "./cover4.jpg"
 import cover5  from "./cover5.jpg"
 import cover6  from "./cover6.jpg"
 import cover7  from "./cover7.jpg"
 import cover8  from "./cover8.jpg"
 import cover9  from "./cover9.jpg"
 
 export const ProjectData = [
     {
         title: "Minimax in PacMan game", 
         image: cover7,
         github:"https://github.com/uta-it-Students/Projetos-Introdu-o-IA-2022-2023/tree/main/P2%20multi-agent%20search", 
         youtube: "https://www.youtube.com/watch?v=vPv0EvW4M1k"
        },
        {
            title: "MiniWorld AutoBuilder", 
            image: cover1,
            github:"https://github.com/NunoLima10/Mini-World-Auto-Builder", 
            youtube: "https://www.youtube.com/watch?v=cMcZrRz0lRA&list=PLLcS6ldQh_lwxzNRjsAgwjVg_CcOJsxFd&index=2"
        },
        {
            title: "YouTube  Downloader", 
            image: cover6,
            github:"https://github.com/NunoLima10/viflex", 
            youtube: "https://www.youtube.com/watch?v=jabUVwC8yQU"
        },
        {
            title: "Ocean Vision WebSite", 
            image: cover8,
            github:"https://github.com/ocean-vision-cv/Ocean-Vision", 
            youtube: "https://oceanvision.netlify.app/"
        },
        {
        title: "Volei cv App", 
        image: cover9,
        github:"https://github.com/Volei-cv", 
        youtube: "https://github.com/Volei-cv"
    },
    {
        title: "Pygame 3D Rendering", 
        image: cover4,
        github:"https://github.com/NunoLima10/3DProjection", 
        youtube: "https://www.youtube.com/watch?v=bQvi-1xsd3s"
    },
    {
        title: "BallonShot Game", 
        image: cover5,
        github:"https://github.com/NunoLima10/BallonShotGame", 
        youtube: "https://www.youtube.com/watch?v=qxnbqYDSkxs"
    },
{
    title: "Script Creation Lesson", 
    image: cover3,
    github:"https://github.com/NunoLima10/MiniWorld-Scripts", 
    youtube: "https://youtu.be/o3kTtwPi1aY"
},
{
    title: "Bridge to Santo Antão", 
    image: cover2,
    github:"https://youtu.be/MF6UPWK8yMU", 
    youtube: "https://youtu.be/MF6UPWK8yMU"
},
]