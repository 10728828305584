import c from "./c.png"
import cplusplus from "./cplusplus.png"
import csharp from "./csharp.png"
import figma from "./figma.png"
import flask from "./flask.png"
import illustrator from "./illustrator.png"
import java from "./java.png"
import javascript from "./javascript.png"
import kotlin from "./kotlin.png"
import lua from "./lua.png"
import photoshop from "./photoshop.png"
import premiere from "./premiere.png"
import pygame from "./pygame.png"
import python from "./python.png"
import react from "./react.png"
import rust from "./rust.png"
import unity from "./unity.png"


export const SoftWaresAndFrameWorksData = [
    {name:"Figma", image: figma},
    {name:"Flask", image: flask},
    {name:"Illustrator", image: illustrator},
    {name:"Photoshop", image: photoshop},
    {name:"Premiere", image: premiere},
    {name:"Pygame", image: pygame},
    {name:"React", image: react},
    {name:"Unity", image: unity},
]

export const LanguagesData = [
    {name:"Python", image: python},
    {name:"Lua", image: lua},
    {name:"Javascript", image: javascript},
    {name:"C", image: c},
    {name:"Java", image: java},
    {name:"kotlin", image: kotlin},
    {name:"Rust", image: rust},
    {name:"C#", image: csharp},
    {name:"C++", image: cplusplus},
]