

const YouTube = ({className}) => {
    return (
        <svg className={className} viewBox="0 0 28 20" fill="#8C8C8C">
            <path d="M27.6986 5.86378C27.6996 4.4335 27.1622 3.05609 26.1948 2.00956C25.2274 0.963028 23.9021 0.32531 22.4864 0.225116C16.8226 -0.0716559 11.0705 -0.0716558 5.446 0.205331C2.67792 0.205331 0.214141 2.89606 0.214141 5.86378C-0.0712988 8.73197 -0.0712988 11.6217 0.214141 14.4899C0.211508 15.8806 0.755146 17.2158 1.72627 18.2037C2.6974 19.1917 4.01706 19.7521 5.39692 19.7626C8.24352 19.9209 11.0967 20 13.9564 20C16.816 20 19.6528 19.9242 22.4667 19.7725C23.8417 19.77 25.1607 19.2232 26.1393 18.2499C27.118 17.2765 27.678 15.9546 27.6986 14.5691C28.1006 11.6814 28.1006 8.7514 27.6986 5.86378ZM25.7354 14.4009C25.7306 14.4403 25.7306 14.4802 25.7354 14.5196C25.7328 14.957 25.6447 15.3895 25.4759 15.7924C25.3072 16.1953 25.0612 16.5607 24.7521 16.8677C24.4429 17.1746 24.0767 17.4171 23.6745 17.5811C23.2722 17.7452 22.8418 17.8276 22.4078 17.8237C16.7577 18.1204 11.0961 18.1204 5.446 17.8237C5.00868 17.8199 4.57648 17.7284 4.17454 17.5547C3.77261 17.381 3.40898 17.1285 3.1048 16.8118C2.80062 16.4951 2.56197 16.1206 2.40274 15.7101C2.24351 15.2996 2.16689 14.8613 2.17731 14.4207C1.89716 11.6349 1.89716 8.82766 2.17731 6.04184C2.18173 6.0057 2.18173 5.96916 2.17731 5.93302C2.16971 5.00449 2.50518 4.10632 3.11844 3.41331C3.73169 2.7203 4.57887 2.28204 5.49508 2.18381C8.30242 2.05521 11.1294 1.99585 13.9564 1.99585C16.7833 1.99585 19.6103 2.05521 22.4176 2.18381H22.4667C24.1452 2.18381 25.7354 4.00401 25.7354 5.93302C25.7305 5.98236 25.7305 6.03207 25.7354 6.08141C26.1197 8.83442 26.1197 11.6281 25.7354 14.3811V14.4009Z" />
            <path d="M18.6581 9.336L11.6496 5.69561C11.5349 5.63585 11.4096 5.59945 11.2809 5.58848C11.1522 5.57752 11.0227 5.59221 10.8996 5.63171C10.7766 5.67121 10.6624 5.73474 10.5638 5.81869C10.4651 5.90264 10.3838 6.00536 10.3245 6.12098C10.2652 6.2366 10.229 6.36285 10.2182 6.49254C10.2073 6.62223 10.2219 6.7528 10.2611 6.87681C10.3003 7.00082 10.3633 7.11584 10.4466 7.21529C10.5299 7.31475 10.6318 7.39669 10.7465 7.45645L16.0766 10.2164L12.1502 12.2642V10.2164C12.1502 9.95406 12.0468 9.70245 11.8627 9.51693C11.6786 9.33141 11.429 9.22719 11.1686 9.22719C10.9083 9.22719 10.6586 9.33141 10.4745 9.51693C10.2905 9.70245 10.187 9.95406 10.187 10.2164V13.8964C10.187 14.1588 10.2905 14.4104 10.4745 14.5959C10.6586 14.7814 10.9083 14.8856 11.1686 14.8856C11.3256 14.8863 11.4804 14.849 11.6202 14.7768L18.6287 11.0968C18.7887 11.0133 18.9229 10.8869 19.0164 10.7316C19.1099 10.5763 19.1591 10.3981 19.1587 10.2164C19.162 10.0378 19.1171 9.86162 19.029 9.7066C18.9408 9.55158 18.8127 9.42352 18.6581 9.336Z" />
        </svg>
    )
}

export default YouTube;