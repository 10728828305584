import './Project-Card.css'
import GitHub from '../react icons/GitHub'
import YouTube from '../react icons/YouTube'

const ProjectCard = ({cardData}) => {
    return (
        <div className='card-item'>
            <img src={cardData.image} alt="capa" className='card-cover' />
            <div className="card-info">
                <p className='card-title'>{cardData.title}</p>
                <div className="card-links-container">
                    <a href= {cardData.github}><GitHub className={"card-link"} /></a>
                    <a href= {cardData.youtube}><YouTube className="card-link" /></a>
                </div>
            </div>
        </div>
    )
}

export default ProjectCard