import './Contact.css'
import Mail from '../react icons/Mail';
import GitHub from '../react icons/GitHub';
import YouTube from '../react icons/YouTube';
import Instagram from '../react icons/Instagram';
import LinkedIn from '../react icons/LinkedIn';
import imagePath from '../../assets/contact-image.jpg'
const Contact = () => {
    return (
        <section id="contact">
            <div className="contact-left-container">
                <p className='contact-call-to-action'>Let's <span className='call-to-action-text-highlight'>start a project</span> together</p>
            </div>
            <div className="contact-right-container">
                <div className='mail-container'>
                    <img className='contact-image' src={imagePath} alt='contact' />
                    <div className='mail'>
                        <Mail className={"mail-link"}/>
                        <p className='email-contact'>nunolima711@gmail.com</p>
                    </div>
                </div>    
                <div className="contact-links-container">
                <a href='https://github.com/NunoLima10'><GitHub className={"contact-link"}/></a>
                <a href='https://www.youtube.com/@NunoLima10'><YouTube className={"contact-link"}/></a>
                <a href='https://www.instagram.com/nunolima10/'><Instagram className={"contact-link"}/></a>
                <a href='https://www.linkedin.com/in/nuno-lima-5899b6251/'><LinkedIn className={"contact-link"} /></a>
                </div>
            </div>
        </section>
        )
};

export default Contact;