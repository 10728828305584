import './SkillsItem.css'


const SkillsItem = ({icon}) =>{
    return(
        <div className='item-container'>
            <img src={icon.image} alt="icon"  className='item-icon'/>
            <p className='item-name'>{icon.name}</p>
        </div>
    )
}

export default SkillsItem;